export const caucasianOptions = {
  showDummyPoints: false,
  showLegend: false,
  showThreshold: true,
}

export const africanAmericanOptions = {
  showDummyPoints: false,
  showLegend: false,
  showThreshold: true,
}
